<template>
  <v-container fluid>
    <v-row>
      <v-col md="2" class="hidden-sm-and-down" />
      <v-col md="8">
        <v-row align="center" style="height: 150px">
          <v-col cols="12" class="headline text-center secondary--text"
            >Hallo, {{ firstName }}
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            v-for="card of cards"
            :key="card.cardId"
            cols="12"
            sm="6"
            lg="4"
          >
            <kanban-card v-bind="card" />
          </v-col>
        </v-row>
      </v-col>
      <v-col md="2" class="hidden-sm-and-down" />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import KanbanCard from "../components/KanbanCard.vue";
export default {
  name: "Home",
  components: { KanbanCard },
  data() {
    return {
      cards: [
        {
          cardLoading: false,
          cardId: 0,
          cardName: "In je mandje",
          cardKPI: 0,
          cardText: "product(en)",
          cardAltText: "er ligt niets in je mandje",
          cardRouteLabel: "Naar je mandje",
          cardRouteName: "ECart",
          cardAltRouteLabel: "Naar je producten",
          cardAltRouteName: "ECatalog"
        },
        {
          cardLoading: false,
          cardId: 1,
          cardName: "In behandeling",
          cardKPI: 0,
          cardText: "bestelling(en)",
          cardAltText: "je hebt geen lopende bestellingen",
          cardRouteLabel: "Naar je bestellingen",
          cardRouteName: "EOrders",
          cardAltRouteLabel: "Start je bestelling",
          cardAltRouteName: "ECatalog"
        }
      ]
    };
  },
  mounted() {
    this.cards[0].cardLoading = true;
    this.$store.dispatch("shoppingCart/fetchShoppingCart").then(() => {
      this.cards[0].cardKPI = this.cartItemCount;
      this.cards[0].cardLoading = false;
    });

    this.cards[1].cardLoading = true;
    this.$store.dispatch("orders/fetchOrderList").then(() => {
      this.cards[1].cardKPI = this.orderCount;
      this.cards[1].cardLoading = false;
    });
  },
  computed: {
    ...mapGetters({
      cartItemCount: "shoppingCart/cartItemCount",
      orderCount: "orders/orderCount",
      firstName: "account/firstName"
    })
  }
};
</script>
