<template>
  <v-card flat outlined shaped :loading="cardLoading">
    <v-card-title>
      {{ cardName }}
    </v-card-title>
    <v-card-text class="my-6 text-center" style="height: 72px">
      <div v-if="cardKPI">
        <span class="text-h2 green--text">
          {{ cardKPI }}
        </span>
        {{ cardText }}
      </div>
      <div v-else>
        {{ cardAltText }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="cardKPI"
        v-show="cardRouteLabel"
        text
        small
        plain
        :to="{ name: cardRouteName }"
      >
        {{ cardRouteLabel }}
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
        v-else
        v-show="cardAltRouteLabel"
        text
        small
        plain
        :to="{ name: cardAltRouteName }"
      >
        {{ cardAltRouteLabel }}
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "KanbanCard",
  props: {
    cardLoading: Boolean,
    cardName: String,
    cardKPI: Number,
    cardText: String,
    cardAltText: String,
    cardRouteLabel: String,
    cardRouteName: String,
    cardAltRouteLabel: String,
    cardAltRouteName: String
  }
};
</script>
